.header { position:fixed; top:0; left:0; z-index:100; width:100%; background:var(--green); box-sizing:border-box; display:flex; align-items:center; justify-content:space-between; padding:0 24px; height:84px; color:#fff; }
.header a { color:#fff; }
.header .front-section { display:flex; align-items:center; }
.header .front-section .logo-wrap { margin-right:60px; }
.header .front-section .logo-wrap .inner { display:flex; align-items:center; font-size:12px; color:#fff; font-weight:300; padding:0; line-height:1.2; }
.header .front-section .logo-wrap .inner img { width:36px; margin-right:8px; }

.client-contents { padding:0; position:relative; height:100vh; box-sizing:border-box; background:var(--green); color:#fff; overflow:hidden; }
.client-contents input[type="text"],
.client-contents input[type="password"],
.client-contents input[type="email"],
.client-contents textarea,
.client-contents select { border:1px solid var(--gray); border-radius:4px; padding:12px 16px; box-sizing:border-box; width:100%; background:unset; font-size:16px; color:#fff; }
.client-contents button { background:var(--lightGreen); padding: 16px 0; width:100%; text-align:center; color:var(--black); border-radius:4px; font-weight:400; line-height:1; }
.client-contents button.down-link { 
  color: #26173E;
    font-weight: 500;
    background: #fff;
    border-radius: 4px;
    opacity: 0.8;
    padding: 16px 0;
    width: 358px;
    margin: 0 auto 16px;
    transition: all 0.3s;
 }
.client-contents .inner { position:relative; display:flex; align-items:center; justify-content:space-between; height:100%; width:calc(100% - 48px); max-width:1000px; margin:0 auto; }
.client-contents .inner .image-wrap { width:30%; position:relative; }
.client-contents .inner .image-wrap img { width:100%; }
.client-contents .inner .contents-wrap { width:40%; position:relative; height:100%; max-height:calc(100vh - 84px); display:flex; flex-direction:column; justify-content:space-around; }
.client-contents .inner .contents-wrap .title-wrap { margin-bottom:0; }
.client-contents .inner .contents-wrap .title-wrap .title { font-size:32px; font-weight:bold; line-height:1.4; text-align:center; margin-bottom:48px; word-break:keep-all; white-space:pre-wrap; }
.client-contents .inner .contents-wrap .title-wrap .title-desc { text-align:center; font-size:16px; font-weight:400; margin:60px auto; }
.client-contents .inner .contents-wrap .sub-title { width:358px; font-size:22px; font-weight:600; margin:0 auto; margin-bottom:48px; line-height:1.4; }
.client-contents .inner .contents-wrap .desc { text-align:center; line-height:1.4; margin-bottom:24px; font-size:16px; white-space:pre-wrap; word-break: keep-all;}
.client-contents .inner .contents-wrap.cms { height:100vh; max-height:100vh; justify-content:flex-start; overflow-y:auto; position:relative; }
.client-contents .inner .contents-wrap.cms::-webkit-scrollbar {
  width: 2px;
}
.client-contents .inner .contents-wrap.cms::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius:8px;
}
.client-contents .inner .contents-wrap.cms::-webkit-scrollbar-track {
  background-color: #555;
}
.client-contents .inner .link-wrap { display:flex; flex-direction:column; margin-bottom:24px; text-align:center; }
.client-contents .inner .link-wrap a { cursor:pointer; color:#26173E; font-weight:500; background:#fff; border-radius:4px; opacity:0.8; padding:16px 0; width:358px; margin:0 auto 8px; transition:all 0.3s;}
.client-contents .inner .link-wrap a:hover { opacity:1; }
.client-contents .inner .description-wrap { width:358px; box-sizing:border-box; background:#d3c8e3; border-radius:8px; padding:24px 16px; margin:0 auto; }
.client-contents .inner .description-wrap .title { font-size:20px; line-height:1.4; font-weight:bold; margin-bottom:16px; color:#26173E; word-break:keep-all; }
.client-contents .inner .description-wrap .description { font-size:16px; font-weight:500; line-height:1.4; color:#26173E; word-break:keep-all; white-space: pre-wrap; }

.client-contents .inner .services-list h3 { font-size:20px; font-weight:600; margin-bottom:24px; }
.client-contents .inner .services-list li { margin-bottom:8px; }
.client-contents .inner .services-list li label { display:flex; align-items:center; cursor:pointer; }
.client-contents .inner .services-list li label input { background:#fff; margin-right:8px; cursor:pointer; }
.client-contents .inner .services-list li label input:checked { background:url('/public/img/icon-checked.png') no-repeat center center; background-size:100%;  }
.client-contents .inner .services-list li label .name { margin-right:4px; }
.client-contents .inner .services-list li label span:not(.name) { font-size:16px; color:#ccc; }
.client-contents .inner .services-list button { margin-top:24px; background:rgba(255,255,255,0.8); font-weight:500; transition:all 0.3s; }
.client-contents .inner .services-list button:hover { background:#fff; }
.client-contents .inner .contents-wrap.cms .login-form { padding-top:84px; }
.client-contents .inner .contents-wrap.cms .login-form .input-wrap .radio-wrap { flex-wrap:wrap; width:calc(100% - 120px); }
.client-contents .inner .contents-wrap.cms .login-form .input-wrap .radio-wrap.wide { width:100%; }
.client-contents .inner .contents-wrap.cms .login-form .input-wrap .radio-wrap.wide input[type="number"] { margin-left:12px; }
.client-contents .inner .contents-wrap.cms .login-form .input-wrap .radio-wrap label { margin:4px 16px 4px 0; }
.login-form { width:358px; position:relative; margin:0 auto; }
.login-form .input-wrap { display:flex; flex-direction:column; background:#EAE6EF; border-radius:8px; padding:16px 12px; margin-bottom:16px; }
.login-form.cms .input-wrap { flex-direction:row; align-items:center; padding:12px; }
.login-form .input-wrap .label { font-size:16px; font-weight:500; color:#26173E; margin-bottom:12px; line-height:1.2; }
.login-form .input-wrap .input-no { color:#000; }
.login-form .input-wrap .input-no input { width:48px; text-align:center; } 
.login-form .input-wrap .input-no span { margin:0 8px; }
.login-form .input-wrap .text { color:#000; }
.login-form .input-wrap input[type="text"],
.login-form .input-wrap input[type="password"],
.login-form .input-wrap input[type="number"] { background:rgba(0,0,0,0); border:0; border-bottom:1px solid #2E1D4F; border-radius:0; padding:8px 0; font-size:14px; color:#000; }
.login-form .input-wrap .radio-wrap { display:flex; align-items:center; flex-wrap:wrap; }
.login-form .input-wrap .radio-wrap label { margin:4px 16px 4px 0; }
.login-form .input-wrap label { display:flex; align-items:center; color:var(--lightGreen); font-size:14px; margin-right:16px; }
.login-form .input-wrap input[type="radio"] { border:1px solid var(--lightGreen); margin-right:4px; }
.login-form .input-wrap input[type="radio"]:checked { box-shadow:0 0 0 1px #fff inset; }
.login-form .input-wrap .inputs { display:flex; align-items:center; justify-content:space-between; margin-bottom:12px; font-size:16px; color:#000; }
.login-form .input-wrap .inputs:last-child { margin-bottom:0; }
.login-form .input-wrap .inputs .label { margin-bottom:0; }
.login-form .input-wrap .text-wrap { font-size:16px; color:#26173E; font-weight:500; }
.login-form .input-wrap .text-wrap span { font-size:15px; color:#777; }
.login-form .sub-desc { font-size:16px; line-height:1.2; white-space:pre-wrap; margin-bottom:24px; }

.login-form.cms .input-wrap .label { margin-bottom:0; width:120px; }
.login-form.cms .input-wrap input[type="text"],
.login-form.cms .input-wrap input[type="password"],
.login-form.cms .input-wrap input[type="number"] { width:calc(100% - 120px); }
.login-form.cms .input-wrap.column { flex-direction:column; align-items:flex-start; }
.login-form.cms .input-wrap.column .label { width:fit-content; text-align:left; margin-bottom:8px; }
.signature-wrap { position:relative; width:100%;}
.signature-canvas { background:#fff; border-radius:8px; }
.login-form .input-wrap.column .button-wrap { display:flex; align-items:center; justify-content:space-between; margin-top:8px; } 
.login-form button { color:#26173E; font-weight:500; background:#fff; border-radius:4px; opacity:0.8; padding:16px 0; width:358px; margin:0 auto 16px; transition:all 0.3s;}
.login-form button:hover { opacity:1; }
.login-form .button { display:block; color:#26173E;  text-align:center; font-weight:500; background:#fff; border-radius:4px; opacity:0.8; padding:16px 0; width:358px; margin:24px auto 16px; transition:all 0.3s;}
.login-form .button:hover { opacity:1; }

.login-form .input-wrap.column .button-wrap button { width:calc((100% - 12px) / 2); margin:0; font-size:12px; padding:8px 0; }
.login-form .error-message { margin-bottom:12px; color:var(--red); }
.guide-txt { font-size:14px; margin-bottom:12px; margin-top:24px; }
.loading-spinner {
  position:fixed; top:50%; left:50%;transform:translate(-50%,-50%); background:#fff; border-radius:8px; padding:24px; width:310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 124px;
  color:#000; font-size:16px; line-height:1.4; text-align:center; font-weight:500; z-index:9999;
}
.loading-spinner p { margin-top:24px; }

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
  border-left: 4px solid #000; /* Black */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



/*로그인 페이지*/
.login-wrap { width:100%; max-width:390px; box-sizing:border-box; padding: 84px 24px; margin:0 auto; }
.login-wrap .title-wrap { margin-bottom:60px; color:#fff; text-align:center; }
.login-wrap .title-wrap .title { font-size:32px; font-weight:bold; margin-bottom:24px; }
.login-wrap .title-wrap .desc { font-size:16px; font-weight:300; }
.login-wrap .login-form input { margin-bottom:12px; }
.login-wrap .sns-wrap { display:flex; align-items:center; justify-content:center; margin-top:72px; }
.login-wrap .sns-wrap button { padding:0; background:unset; width:fit-content; margin:0 12px; }
.login-wrap .link-join { text-align:center; font-size:16px; font-weight:400; margin-top:48px; color:var(--gray); }
.login-wrap .link-join a { color:var(--lightGreen); margin-left:16px; }
/* 회원가입 페이지 */
.sign-wrap { width:100%; max-width:390px; box-sizing:border-box; padding: 84px 24px; margin:0 auto; }
.sign-wrap .title-wrap { margin-bottom:60px; color:#fff; text-align:left; }
.sign-wrap .title-wrap .title { font-size:32px; font-weight:bold; margin-bottom:24px; line-height:1.2; word-break:keep-all;}
.sign-wrap .title-wrap .desc { font-size:16px; font-weight:300; }
.sign-wrap .login-form input { margin-bottom:12px; }
.sign-wrap .link-login { margin-top:32px; color:var(--gray); }
.sign-wrap .link-login a { color:var(--lightGreen); margin-left:8px; }
.sign-wrap .login-form .authcode-wrap { display:flex; align-items:center; border:1px solid var(--gray); border-radius:4px; padding:12px 16px; margin-bottom:12px; }
.sign-wrap .login-form .authcode-wrap input { border:0; margin:0; padding:0; }
.sign-wrap .login-form .countdown { color:var(--lightGreen); }
.sign-wrap .login-form .input-wrap { display:flex; align-items:center; border:1px solid var(--gray); border-radius:4px; padding:12px 16px; margin-bottom:12px; }
.sign-wrap .login-form .input-wrap input  { border:0; margin:0; padding:0; }
.sign-wrap .login-form .input-wrap button { padding:0; width:80px; background:unset; color:var(--lightGreen); font-size:14px;  }
.sign-wrap .login-form .txtConfirm { font-size:12px; margin-bottom:20px; color:var(--lightGreen); padding:0 12px;}
.sign-wrap .login-form .txtWarn { font-size:12px; margin-bottom:20px; color:var(--red); padding:0 12px;}
.sign-wrap .login-form .password-guide { padding:0 12px 36px; }
.sign-wrap .login-form .password-guide .title { font-size:14px; font-weight:400; color:var(--gray); margin-bottom:4px; }
.sign-wrap .login-form .password-guide .invalid { font-size:12px; color:var(--gray); font-weight:400; padding:4px 0; }
.sign-wrap .login-form .password-guide .valid { font-size:12px; color:var(--lightGreen); font-weight:400; padding:4px 0; }
.consent-section { margin-bottom:32px; width:358px; margin:0 auto 12px; }
.consent-section.cms { margin-bottom:32px; }
.consent-section .desc-wrap { font-size:12px; color:#ccc; margin-bottom:12px; }
.consent-section .agree { display:flex; align-items:center; justify-content:space-between; font-size:14px; font-weight:400; margin-bottom:8px; }
.consent-section .agree label { display:flex; align-items:center; cursor:pointer; font-size:16px; }
.consent-section .agree input { margin:0; margin-right:8px; cursor:pointer; }
.consent-section .agree button { width:fit-content; background:unset; padding:0; color:#fff; text-decoration: underline; font-size:16px; }
.consent-section .agree button.viewMore { margin:0; }
.consent-section input[type="checkbox"] { background:#fff; }
.consent-section input[type="checkbox"]:checked { background:var(--lightGreen); background:url('/public/img/icon-checked.png') no-repeat center center; background-size:100%; }
/* Client - game */
.game-detail { padding:12px 0; }
.game-detail .info-wrap { display:flex; align-items:center; }
.game-detail .info-wrap .thumb-wrap { position:relative; width:176px; margin-right:48px; }
.game-detail .info-wrap .thumb-wrap img { width:100%; }
.game-detail .info-wrap .info .title { font-size:32px; font-weight:bold; margin-bottom:32px; }
.game-detail .info-wrap .info .description { font-size:16px; font-weight:300; line-height:1.3; max-width:493px; }

.game-detail .filters { display:flex; justify-content:space-between; padding:32px 0; margin-bottom:84px; }
.game-detail .filters .filter { display:flex; align-items:center; }
.game-detail .filters .filter select { width:213px; border:1px solid #262626; border-radius:4px; padding:8px 16px; margin-right:12px; width:213px; box-sizing:border-box; color:#fff; font-weight:500; background:rgba(36,36,36,0.2) }
.game-detail .filters .filter button { width:213px; font-size: 14px; font-weight: 400; line-height: 1.5; padding: 7px 12px; border-radius: 4px; background: var(--green); color: #fff; }
.game-detail .filters .search { display:flex; align-items:center; border:1px solid #262626; border-radius:4px; padding:11px 24px; }
.game-detail .filters .search input { border:0; padding:0; font-size:14px; line-height:1; }
.game-detail .filters .search button { width:14px; height:14px; background:url('/public/img/icon-search.png'); font-size:0; background-size:14px 14px; padding:0;}

.game-detail .util-wrap { padding-top:84px; margin-bottom:24px; }
.game-detail .util-wrap .totalCnt { font-size:32px; font-weight:bold; }

.game-detail .list-wrap { display:flex; flex-wrap:wrap; margin-bottom:200px;}
.game-detail .list-wrap > div { position:relative; width:calc((100% - 96px) / 5); margin-right:24px; box-sizing:border-box; padding:24px 30px; border:1px solid var(--gray); border-radius:8px; text-align:center; }
.game-detail .list-wrap > div:nth-child(5n) { margin-right:0; }
.game-detail .list-wrap .thumb-wrap { position:relative; width:100%; padding-top:100%; border-radius:358px; overflow:hidden; margin-bottom:12px; line-height:0; }
.game-detail .list-wrap .thumb-wrap img { position:absolute; top:0; left:0; width:100%; }
.game-detail .list-wrap .playId { font-size:22px; font-weight:bold; margin-bottom:8px; line-height:1.3; color:#fff; }
.game-detail .list-wrap .name { display:flex; align-items:center; justify-content:center; font-size:12px; font-weight:400; color:var(--gray); margin-bottom:8px; }
.game-detail .list-wrap .name img { margin-right:8px; }
.game-detail .list-wrap .team { display:flex; align-items:center; justify-content:center; font-size:14px; color:#fff; }
.game-detail .list-wrap > div button { font-size:14px; font-weight:bold; display:flex; align-items:center; padding:6px 12px; border-radius:4px; width:fit-content; margin:16px auto 0; }
.game-detail .list-wrap > div button img { margin-right:4px; height:16px; }
.game-detail .list-wrap > div button.disactive { background:unset; color:var(--gray); border:1px solid var(--gray); }
.player-wrap { padding-bottom:120px; }
.player-wrap > .info-wrap { display:flex; align-items:center; box-sizing:border-box; padding:48px 48px 48px 64px; border-radius:8px; border:0; background:var(--bgGray); margin-bottom:60px; }
.player-wrap > .info-wrap .thumb-wrap { position:relative; line-height:0; width:220px; height:220px; border-radius:220px; overflow:hidden; background:#fff; margin-right:64px; }
.player-wrap > .info-wrap .thumb-wrap img { width:100%; }
.player-wrap > .info-wrap .info .playId { font-size:36px; font-weight:bold; margin-bottom:20px; }
.player-wrap > .info-wrap .info .sns-wrap { display:flex; align-items:center; margin-bottom:24px; }
.player-wrap > .info-wrap .info .sns-wrap a { margin-right:12px; }
.player-wrap > .info-wrap .info .sns-wrap a img { width:18px; filter:invert(1); }
.player-wrap > .info-wrap .info .field { display:flex; align-items:center; margin-bottom:8px; height:24px; }
.player-wrap > .info-wrap .info .field .label { font-size:14px; font-weight:400; color:var(--gray); width:36px; margin-right:24px; }
.player-wrap > .info-wrap .info .field .data { display:flex; align-items:center; font-size:14px; font-weight:400; color:#fff; }
.player-wrap > .info-wrap .info .field.column { flex-direction:column; align-items:flex-start; height:unset; }
.player-wrap > .info-wrap .info .field.column .label { margin-bottom:24px; }
.player-wrap > .info-wrap .info .field .player-box { display:flex; align-items:center; flex-direction:column; margin-right:12px; color:#fff; }
.player-wrap > .info-wrap .info .field .player-box img { width:88px; height:88px; border-radius:88px; margin-bottom:12px; }

.player-wrap .section-wrap { margin-bottom:60px; }
.player-wrap .section-wrap .section-title { font-size:24px; font-weight:bold; margin-bottom:20px; display:flex; align-items:center; }
.player-wrap .section-wrap .section-title img { margin-right:6px; width:24px; }
.player-wrap .section-wrap .section-title .game-icon { filter:invert(1); margin-right:6px;}
.player-wrap .section-wrap .category-group { box-sizing:border-box; padding:24px; border-radius:8px; border:0; background:var(--bgGray); margin-bottom:12px; }
.player-wrap .section-wrap .category-group h3 { font-size:20px; font-weight:bold; margin-bottom:24px; }
.player-wrap .section-wrap .category-group .tools-list { display:flex; flex-wrap:wrap; }
.player-wrap .section-wrap .category-group .tools-list .tool-item { position:relative; display:flex; flex-direction:column; width:calc((100% - 168px) / 4); margin-right:56px; color:#fff; }
.player-wrap .section-wrap .category-group .tools-list .tool-item:nth-child(4n) { margin-right:0; }
.player-wrap .section-wrap .category-group .tools-list .tool-item img { width:100%; margin-bottom:12px; }
.player-wrap .section-wrap .category-group .tools-list .tool-item .category { font-size:14px; font-weight:600; width:fit-content; border-radius:4px; color:#000; background:var(--gray); padding:4px 8px; line-height:1; margin-bottom:8px; }
.player-wrap .section-wrap .category-group .tools-list .tool-item .name { font-size:16px; font-weight:bold; }

.player-wrap .section-wrap .setting-item { box-sizing:border-box; padding:24px; border-radius:8px; border:0; background:var(--bgGray); margin-bottom:12px; }
.player-wrap .section-wrap .setting-item .title { display:flex; align-items:center; font-size:20px; font-weight:bold; margin-bottom:24px; }
.player-wrap .section-wrap .setting-item .title img { width:22px; margin-right:12px; }
.player-wrap .section-wrap .setting-item .dynamic-fields { display:flex; flex-wrap:wrap; }
.player-wrap .section-wrap .setting-item .dynamic-fields .dynamic-field { width:160px; }
.player-wrap .section-wrap .setting-item .dynamic-fields .dynamic-field .field-name { color:var(--gray); font-weight:600; font-size:14px; margin-bottom:8px; }
.player-wrap .section-wrap .setting-item .dynamic-fields .dynamic-field .field-value { font-size:16px; font-weight:600; }

.board-title-wrap { display:flex; align-items:center; justify-content:space-between; padding-bottom:8px; border-bottom:1px solid var(--gray); margin-bottom:12px; }
.board-title-wrap h2 { font-size:22px; font-weight:bold; padding-left:8px; border-left:4px solid #fff; }
.board-title-wrap .subCate-wrap { display:flex; align-items:center; }
.board-title-wrap .subCate-wrap li { margin-left:20px; padding:6px 8px; border-radius:4px; font-size:16px; cursor:pointer; }
.board-title-wrap .subCate-wrap li.selected { background:var(--lightGreen); color:#000; font-weight:600; }
.board-title-wrap .subCate-wrap a { color:#fff; }

.board-table-head { display:flex; align-items:center; background:var(--bgGray); }
.board-table-head li { font-size:16px; font-weight:bold; text-align:center; padding:24px 0; border-bottom:1px solid var(--gray); }
.board-table-head li.category { width:120px; }
.board-table-head li.writer { width:120px; }
.board-table-head li.subject { width:calc(100% - 540px); }
.board-table-head li.date { width:100px; }
.board-table-head li.hit { width:100px; }
.board-table-head li.recommand { width:100px; }

.board-wrap .btnWrite { width:fit-content; display:block; padding:6px 8px; font-size:16px; border-radius:0; margin:0 0 12px auto; font-weight:600; }
.board-wrap .pagination { display:flex; align-items:center; justify-content:center; margin-top:40px; }
.board-wrap .pagination button { width:fit-content; padding:6px 8px 4px; background:unset; border-radius:2px; color:#fff; font-size:16px; font-weight:400; margin:0 4px; }
.board-wrap .pagination button.active { font-weight:500; background:var(--bgGray) }
.board-inner ul li { display:flex; align-items:center; background:var(--bgGray); }
.board-inner ul li:nth-child(2n-1) { background:unset; }
.board-inner ul li div { display:flex; align-items:center; }
.board-inner ul li span { display:none;}
.board-inner p { font-size:14px; font-weight:400; text-align:center; padding:12px 0; }
.board-inner p.category { width:120px; }
.board-inner p.writer { width:120px; }
.board-inner p.subject { width:calc(100% - 540px); text-align:left; }
.board-inner p.subject a { color:#fff; }
.board-inner p.date { width:100px; }
.board-inner p.hit { width:100px; }
.board-inner p.recommand { width:100px; }

.post-detail .post-title { display:flex; align-items:center; justify-content:space-between; padding-bottom:8px; border-bottom:1px solid var(--gray); }
.post-detail .post-title h1 { font-size:22px; font-weight:bold; }
.post-detail .post-title .date { font-size:16px; font-weight:400; }
.post-detail .post-info { display:flex; align-items:center; justify-content:space-between; padding:12px 0; border-bottom:1px solid var(--bgGray); font-size:14px; }
.post-detail .post-info .info-wrap { display:flex; align-items:center; }
.post-detail .post-info .info-wrap p { margin-left:24px; }
.post-detail .post-contents { padding:24px; margin-bottom:24px; }
.post-detail .recommand-wrap { display:flex; align-items:center; justify-content:center; margin-top:120px; }
.post-detail .recommand-wrap button { display:flex; align-items:center; padding:8px 16px; border-radius:4px; border:1px solid var(--gray); font-weight:700; background:unset; color:#fff; width:fit-content; }
.post-detail .recommand-wrap button img { margin-right:8px; }
.post-detail .recommand-wrap button.active { background:var(--lightGreen); color:#000; border:1px solid var(--lightGreen); }
.post-detail .recommand-wrap button.active img { filter:invert(1); }
.post-detail .comment-wrap { padding-top:24px; }
.post-detail .comment-wrap .comment-sum { display:flex; align-items:center; font-size:16px; font-weight:bold; margin-bottom:12px; }
.post-detail .comment-wrap .comment-sum img { margin-right:12px; }
.post-detail .comment-wrap .comment-write { margin-top:24px; padding-bottom:24px; border-bottom:1px solid var(--bgGray); margin-bottom:24px; }
.post-detail .comment-wrap .comment-write .write-wrap { display:flex; align-items:center; }
.post-detail .comment-wrap .comment-write .write-wrap textarea { background:var(--bgGray); border:0; }
.post-detail .comment-wrap .comment-write .write-wrap button { width:120px; height:68px; margin-left:12px; }

.post-detail .comment-wrap .comment-list > li { padding:0; }
.post-detail .comment-wrap .comment-list > li > ul { padding:0; }
.post-detail .comment-wrap .comment-list > li > ul li > div { padding-left:24px; }
.post-detail .comment-wrap .comment-list > li > ul li > p { padding-left:24px; }
.post-detail .comment-wrap .comment-list .info-wrap { display:flex; align-items:center; justify-content:space-between; margin-bottom:24px;}
.post-detail .comment-wrap .comment-list .info-wrap .info { display:flex; align-items:center; }
.post-detail .comment-wrap .comment-list .info-wrap .info .writer { font-size:14px; font-weight:bold; margin-right:12px; }
.post-detail .comment-wrap .comment-list .info-wrap .info .date { font-size:12px; font-weight:300; color:var(--gray); }
.post-detail .comment-wrap .comment-list .info-wrap .controll { display:flex; align-items:center; }
.post-detail .comment-wrap .comment-list .info-wrap .controll button { background:unset; padding:0; }
.post-detail .comment-wrap .comment-list .comment-contents { padding-bottom:24px; border-bottom:1px solid var(--bgGray); font-size:14px; line-height:1.3; font-weight:400; margin-bottom:24px; }


.best-post-wrap { display:flex; align-items:flex-start; flex-wrap:wrap; margin-bottom:80px; }
.best-post-wrap .category-section { position:relative; width:calc((100% - 120px) / 4); margin-right:30px; box-sizing:border-box; padding:24px; min-height:190px; border-radius:8px; border:1px solid var(--gray); }
.best-post-wrap .category-section:nth-child(4n) { margin-right:0; }
.best-post-wrap .category-section h3 { font-size:22px; font-weight:bold; margin-bottom:16px; }
.best-post-wrap .category-section .best-inner li { display:flex; align-items:center; margin-bottom:8px; position:relative; width:100%; }
.best-post-wrap .category-section .best-inner li:last-child { margin-bottom:0; }
.best-post-wrap .category-section .best-inner li .no { font-size:14px; font-weight:bold; width:20px; margin-right:4px; }
.best-post-wrap .category-section .best-inner li .subject { position:relative; width:calc(100% - 24px); overflow:hidden; height:14px;}
.best-post-wrap .category-section .best-inner li .subject a { position:relative; display:block; font-size:14px; font-weight:400; color:#fff; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.best-post-wrap .category-section .best-inner li:nth-child(1) a { animation: airport 10s 0; animation-iteration-count: infinite; }
.best-post-wrap .category-section .best-inner li:nth-child(2) a { animation: airport 10s 1s; animation-iteration-count: infinite; }
.best-post-wrap .category-section .best-inner li:nth-child(3) a { animation: airport 10s 2s; animation-iteration-count: infinite; }
.best-post-wrap .category-section .best-inner li:nth-child(4) a { animation: airport 10s 3s; animation-iteration-count: infinite; }
.best-post-wrap .category-section .best-inner li:nth-child(5) a { animation: airport 10s 4s; animation-iteration-count: infinite; }
.client-table-wrap { border-radius:0; overflow:hidden; }
.client-table-wrap .table-head { display:flex; align-items:center; background:var(--bgGray); }
.client-table-wrap .table-head > div { text-align:center; padding:24px 0; min-width:100px; }
.client-table-wrap .table-head > div.spec-header { padding-left:12px; padding-right:12px; }
.client-table-wrap .table-head .idx { width:100px; }
.client-table-wrap .table-head .game { width:120px; }
.client-table-wrap .table-head .tool { width:100%; }

.client-table-wrap .table-row { display:flex; align-items:center; }
.client-table-wrap .table-row:nth-child(2n) { background:var(--bgGray); }
.client-table-wrap .table-row > div { text-align:center; padding:12px 0; min-width:100px; }
.client-table-wrap .table-row > td { min-width:100px; text-align:center; padding:0 12px; }
.client-table-wrap .table-row .idx { width:100px; }
.client-table-wrap .table-row .game { width:120px; }
.client-table-wrap .table-row .tool { width:100%; text-align:left; display:flex; align-items:center; }
.client-table-wrap .table-row .tool a { color:#fff; }
.client-table-wrap .table-row .tool img { width:54px; margin-right:12px; }

.mypage-wrap { display:flex; align-items:center; padding:24px 48px 48px; border-bottom:1px solid var(--bgGray); margin-bottom:60px; }
.mypage-wrap .thumb-wrap { position:relative; width:196px; margin-right:64px; }
.mypage-wrap .thumb-wrap img { width:100%; }
.mypage-wrap .user-wrap .field { display:flex; align-items:center; margin-bottom:12px; }
.mypage-wrap .user-wrap .field .label { font-size:16px; color:var(--gray); font-weight:300; width:48px; margin-right:24px; }
.mypage-wrap .user-wrap .field .data { font-size:16px; font-weight:400; color:#fff; padding:8px 0; border-bottom:1px solid #111; }
.mypage-wrap .user-wrap .field .input-wrap { display:flex; align-items:center; padding:8px 0; }
.mypage-wrap .user-wrap .field .input-wrap input  { border:0; margin:0; padding:8px 0; border-radius:0; border-bottom:1px solid var(--gray); }
.mypage-wrap .user-wrap .field .input-wrap button { padding:0; width:80px; background:unset; color:var(--lightGreen); font-size:14px;  }

.mycontents-wrap .tabs { display:flex; align-items:center; margin-bottom:48px; }
.mycontents-wrap .tabs button { background:unset; padding:0; width:fit-content; font-size:24px; font-weight:600; margin-right:48px; color:var(--gray); }
.mycontents-wrap .tabs button.active { color:#fff; font-weight:bold; }
.mycontents-wrap .error { font-size:16px; color:var(--gray); text-align:center; padding:60px 0; }


.mycontents-wrap .favorite-list { display:flex; flex-wrap:wrap; margin-bottom:200px;}
.mycontents-wrap .favorite-list > div { position:relative; width:calc((100% - 96px) / 5); margin-right:24px; box-sizing:border-box; padding:24px 30px; border:1px solid var(--gray); border-radius:8px; text-align:center; }
.mycontents-wrap .favorite-list > div:nth-child(5n) { margin-right:0; }
.mycontents-wrap .favorite-list .thumb-wrap { position:relative; width:100%; padding-top:100%; border-radius:358px; overflow:hidden; margin-bottom:12px; line-height:0; }
.mycontents-wrap .favorite-list .thumb-wrap img { position:absolute; top:0; left:0; width:100%; }
.mycontents-wrap .favorite-list .playId { font-size:22px; font-weight:bold; margin-bottom:8px; line-height:1.3; color:#fff; }
.mycontents-wrap .favorite-list .name { display:flex; align-items:center; justify-content:center; font-size:12px; font-weight:400; color:var(--gray); margin-bottom:8px; }
.mycontents-wrap .favorite-list .name img { margin-right:8px; }
.mycontents-wrap .favorite-list .team { display:flex; align-items:center; justify-content:center; font-size:14px; color:#fff; }
.mycontents-wrap .favorite-list > div button { font-size:14px; font-weight:bold; display:flex; align-items:center; padding:6px 12px; border-radius:4px; width:fit-content; margin:8px auto 0; }
.mycontents-wrap .favorite-list > div button img { margin-right:4px; height:16px; }
.mycontents-wrap .favorite-list > div button.disactive { background:unset; color:var(--gray); border:1px solid var(--gray); }


.comment-wrap { padding-top:24px; }
.comment-wrap .comment-sum { display:flex; align-items:center; font-size:16px; font-weight:bold; margin-bottom:12px; }
.comment-wrap .comment-sum img { margin-right:12px; }
.comment-wrap .comment-write { margin-top:24px; padding-bottom:24px; border-bottom:1px solid var(--bgGray); margin-bottom:24px; }
.comment-wrap .comment-write .write-wrap { display:flex; align-items:center; }
.comment-wrap .comment-write .write-wrap textarea { background:var(--bgGray); border:0; }
.comment-wrap .comment-write .write-wrap button { width:120px; height:68px; margin-left:12px; }
.comment-wrap .comment-list > li { padding:0; }
.comment-wrap .comment-list > li > ul { padding:0; }
.comment-wrap .comment-list > li > ul li > div { padding-left:24px; }
.comment-wrap .comment-list > li > ul li > p { padding-left:24px; }
.comment-wrap .comment-list .info-wrap { display:flex; align-items:center; justify-content:space-between; margin-bottom:24px;}
.comment-wrap .comment-list .info-wrap .info { display:flex; align-items:center; }
.comment-wrap .comment-list .info-wrap .info .writer { font-size:14px; font-weight:bold; margin-right:12px; }
.comment-wrap .comment-list .info-wrap .info .date { font-size:12px; font-weight:300; color:var(--gray); }
.comment-wrap .comment-list .info-wrap .controll { display:flex; align-items:center; }
.comment-wrap .comment-list .info-wrap .controll button { background:unset; padding:0; }
.comment-wrap .comment-list .comment-contents { padding-bottom:24px; border-bottom:1px solid var(--bgGray); font-size:14px; line-height:1.3; font-weight:400; margin-bottom:24px; }

.write-form .write-group { margin-bottom:24px; }
.write-form .write-group label { font-size:16px; font-weight:500; margin-bottom:8px; display:block; }
.ql-editor {
  min-height: 600px; /* 에디터의 최소 높이 설정 */
  position: relative; /* 부모 컨테이너로 설정 */
}

.ql-toolbar.ql-snow { border:1px solid var(--gray) !important; }
.ql-container.ql-snow { border:1px solid var(--gray) !important; border-top:0 !important; }
.ql-editor::before {
  content: attr(data-placeholder); /* Quill의 placeholder를 위한 컨텐츠 */
  position: absolute;
  left: 15px; /* 텍스트 시작 위치에 맞게 설정 */
  top: 15px; /* 텍스트 시작 위치에 맞게 설정 */
  color: #999;
  pointer-events: none; /* placeholder는 클릭 불가능하게 */
}

.ql-editor::after {
  content: ''; /* 빈 content로 에디터 전역을 클릭 가능하게 설정 */
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* 입력 영역보다 앞에 오도록 설정 */
  cursor: text; /* 텍스트 입력을 나타내는 커서 */
}
.write-form button[type="submit"] { width:100px; margin:24px auto; display:block; font-weight:500; }

/* select 요소 스타일링 */
.client-contents select {
  color: #000; /* 텍스트 색상 */
  border: 1px solid var(--gray); /* 테두리 (선택사항) */
  padding:8px 4px;
  font-size:14px;
}

/* option 요소 스타일링 */
.client-contents select option {
  background-color: #fff; /* 배경색 */
  color: #000; /* 텍스트 색상 */
}

.tool-category-list { display:flex; align-items:center; justify-content:flex-start; margin-bottom:24px;}
.tool-category-list button { width:fit-content; background:unset; color:#fff;  margin-right:20px; padding:6px 8px; border-radius:4px; font-size:16px; }
.tool-category-list button.active { background:var(--lightGreen); color:#000; font-weight:600; }

.client-table-wrap .filters { width:100%; flex-direction:column; border-radius:0; border:0; padding:0; overflow: hidden; background:#111; margin-bottom:24px; }
.client-table-wrap .filters .title { font-size:20px; font-weight:600; text-align:left; padding:16px 0;  }
.client-table-wrap .filters .filter-group { display:flex; align-items:center; background:var(--bgGray); border:1px solid #333; border-bottom:0; }
.client-table-wrap .filters .filter-group:nth-last-child(2) { border-bottom:1px solid #333; }
.client-table-wrap .filters .filter-group > label { font-size:14px; font-weight:500; display:flex; align-items:flex-start; width:100%; }
.client-table-wrap .filters .filter-group > label span { padding:12px 24px; display:flex; box-sizing:border-box; align-items:center; justify-content:space-between;  width:160px; background:var(--bgGray); }
.client-table-wrap .filters .filter-group .label-wrap { padding:12px 24px; display:flex; box-sizing:border-box; align-items:center; justify-content:space-between;  width:160px; background:var(--bgGray); }
.client-table-wrap .filters .filter-group .label-wrap label { font-size:14px; font-weight:500; }
.client-table-wrap .filters .filter-group .label-wrap div { font-size:14px; color:var(--gray) }
.client-table-wrap .filters .filter-group .slide-wrap { padding:12px 24px; background:#111; width:calc(100% - 160px); }
.client-table-wrap .filters .filter-group .slide-wrap > div { font-size:14px; color:var(--gray); }
.client-table-wrap .filters .filter-group .check-wrap { padding:12px 24px; background:#111; width:calc(100% - 160px); display:flex; align-items:center; flex-wrap:wrap; }
.client-table-wrap .filters .filter-group .check-wrap label { cursor:pointer; display:flex; align-items:center; font-size:14px; font-weight:300; margin-right:16px; }
.client-table-wrap .filters .filter-group .check-wrap label input { width:16px; height:16px; border:1px solid var(--gray); border-radius:2px; margin-right:4px; }
.client-table-wrap .filters button { border-radius:8px; margin-top:12px; background:unset; border:1px solid var(--gray); color:var(--gray); transition:all 0.3s; }
.client-table-wrap .filters button:hover { background:var(--lightGreen); color:#000; border:1px solid var(--lightGreen); }
.client-table-wrap .filters .filter-group .check-wrap label input[type="checkbox"]:checked { background:url('/public/img/icon-checked.png') no-repeat center center; background-size:100%; }
/* 슬라이더 궤적(track) 스타일 */
.horizontal-slider .track {
  background: var(--gray); /* 기본 트랙 색상 */
  height: 2px; /* 트랙 높이 */
  border-radius: 0px; /* 둥근 모서리 */
}

/* 슬라이더의 선택된 범위(active track) */
.horizontal-slider .track-1 {
  background: var(--lightGreen); /* 선택된 범위 색상 */
  height: 2px; /* 선택된 범위 높이 */
  border-radius: 0px; /* 둥근 모서리 */
}

/* 슬라이더 손잡이(thumb) 스타일 */
.horizontal-slider .thumb {
  height: 18px; /* 손잡이 높이 */
  width: 8px; /* 손잡이 너비 */
  background-color: var(--lightGreen); /* 손잡이 색상 */
  border-radius: 4px; /* 원형 손잡이 */
  cursor: pointer; /* 커서 변경 */
  display: flex; /* 텍스트 가운데 정렬 */
  justify-content: center; /* 텍스트 가운데 정렬 */
  align-items: center; /* 텍스트 가운데 정렬 */
  color: white; /* 텍스트 색상 */
  font-size: 0; /* 텍스트 크기 */
  font-weight: 300; /* 텍스트 굵기 */
  top:-8px;
}

/* 슬라이더 전체 길이(track)의 스타일 */
.horizontal-slider {
  width: 360px; /* 전체 너비 */
  margin-top:24px;
  padding-bottom:16px;  
}

.horizontal-slider.slider-default .track-1 { opacity:0.4; }


.modal-overlay { position:fixed; top:0; left:0; background:rgba(0,0,0,0.8); width:100%; height:100%; z-index:999; }
.modal-overlay .modal-content { position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); background:#fff; box-sizing:border-box; border-radius: 8px; padding:40px 24px;  width:358px;  }
.modal-overlay .modal-content .modal-header { display:flex; align-items:center; justify-content:space-between; padding-bottom:4px; border-bottom:1px solid rgba(0,0,0,0.4); margin-bottom:12px; }
.modal-overlay .modal-content h2 { font-size:20px; color:#000; font-weight:bold; margin-bottom:36px; } 
.modal-overlay .modal-content .modal-header button { width:12px; height:13px; background:url('/public/img/icon-close.png'); font-size:0; background-size:12px 13px; padding:0; line-height:1; }
.modal-overlay .modal-content .modal-body { color:#000; font-size:14px; line-height:1.2; margin-bottom:32px; max-height:300px; overflow-y:auto; white-space:pre-wrap; word-break: keep-all;}
.modal-overlay .modal-close { width:100%; text-align:center; background:unset; border:1px solid var(--gray); color:var(--gray); border-radius:4px; padding:8px 0;}
.modal-overlay .modal-table { position:relative; width:892px; }
.modal-overlay .modal-table .table-head { display:flex; align-items:center; background:#262626; }
.modal-overlay .modal-table .table-head > div { font-size:16px; font-weight:500; color:#fff; padding:24px; box-sizing:border-box; text-align:center; }
.modal-overlay .modal-table .table-head > div.idx { width:100px; }
.modal-overlay .modal-table .table-head > div.thumbnail { width:140px; }
.modal-overlay .modal-table .table-head > div.cate { width:140px; }
.modal-overlay .modal-table .table-head > div.controll { width:140px; }
.modal-overlay .modal-table .table-head > div.name { width:calc(100% - 520px); }
.modal-overlay .modal-table .table-body li { display:flex; align-items:center; background:#fff; }
.modal-overlay .modal-table .table-body li:nth-child(2n) { background:#f0f0f0; }
.modal-overlay .modal-table .table-body li div { font-size:16px; font-weight:500; color:#000; padding:4px 20px; box-sizing:border-box; text-align:center; }
.modal-overlay .modal-table .table-body li .idx { width:100px; }
.modal-overlay .modal-table .table-body li .thumbnail { width:140px; }
.modal-overlay .modal-table .table-body li .thumbnail img { width:60px; }
.modal-overlay .modal-table .table-body li .cate { width:140px; }
.modal-overlay .modal-table .table-body li .controll { width:140px; }
.modal-overlay .modal-table .table-body li .name { width:calc(100% - 520px); text-align:left;}
.modal-overlay .modal-table .table-body li div.controll button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; color: var(--gray); border:1px solid var(--gray);}
.modal-overlay .modal-table .table-body li div.controll button.delete { background:var(--red); border:1px solid var(--red); color:#fff; margin-left:8px; }
.modal-overlay .filters { padding:0; margin-bottom:24px; }
.modal-overlay .filters .btn { width:14px; height:14px; background:url('/public/img/icon-search.png'); font-size:0; background-size:14px 14px; cursor:pointer; }
.modal-overlay .filters .search { padding:12px 16px; border-radius:0; width:100%; justify-content:space-between; }
.modal-overlay .filters .search input { width:100%; }
.modal-overlay .modal-table .table-title { margin-top:12px; font-size:16px; font-weight:bold; margin-bottom:12px; }
.modal-overlay .modalSubmit { background:var(--green); line-height:1; color:#fff; text-align:center; width:100%; font-size:16px; font-weight:bold; padding:16px 0; border-radius:4px; margin-left:0; margin-top:32px; }
.modal-overlay .modal-body { margin-top:12px; }
.modal-overlay .modal-body label { display:block; font-size:16px; font-weight:bold; color:var(--black); padding-top:12px; margin-bottom:12px; }
.modal-overlay .modal-body input[type='text'] { width:100%; }
.modal-overlay .modal-body select { width:100%; }
.modal-overlay .btnSubmit { font-size:14px; line-height:1; color:#fff; background:var(--green); border-radius:4px; padding:6px 8px; }
.modal-overlay .modal-body form > div { margin-bottom:12px; }
.modal-overlay .modal-body .label-wrap { display:flex; align-items:center; justify-content: space-between; margin-bottom:8px; }
.modal-overlay .modal-body form > div > .input-wrap { display:flex; align-items:center; margin-bottom:4px; }
.modal-overlay .modal-body form > div > .input-wrap input:last-child { margin-left:8px; }
.modal-overlay .modal-body form > div .d-flex { display:flex; align-items:center; margin-bottom:4px;}
.modal-overlay .modal-body form > div .d-flex input[type="text"] { width:120px; margin-right:12px; height:32px; }
.modal-overlay .modal-body form .delete { background:var(--red); color:#fff; font-size:14px; padding:4px 8px; }
.modal-overlay .modal-body form .confirm { background:var(--green); color:#fff; font-size:14px; padding:4px 8px; margin-top:12px; }
.modal-overlay .modal-footer { display:flex; align-items:center; justify-content:center; }
.modal-overlay .modal-footer .delete { background:var(--red); color:#fff; font-size:14px; padding:4px 8px; }
.modal-overlay .modal-footer .confirm { background:var(--green); margin-right:4px; color:#fff; font-size:14px; padding:4px 8px; }
.modal-overlay .modal-content p { text-align:center; white-space:pre-wrap; line-height:1.4; font-weight:bold; }
.modal-overlay .modal-content .title { text-align:left; margin-bottom:12px; font-size:16px; }
.modal-overlay .modal-content .desc { margin-bottom:24px; line-height:1.2}
.modal-overlay .modal-buttons { display:flex; align-items:Center; justify-content:center; margin-top:24px; }
.modal-overlay .modal-buttons .delete { border:1px solid #ccc; font-size:14px; padding:4px 16px; }
.modal-overlay .modal-buttons .confirm { background:var(--green); margin-right:4px; color:#fff; font-size:14px; padding:4px 16px; }

.btn-sidebar { display:none; }
.sidebar { display:none; }

input[type="checkbox"]:checked { background:url('/public/img/icon-checked.png') no-repeat center center; background-size:100%; box-shadow:unset; border:1px solid #fff; }

.mo { display:none !important; }
.pc { display:block; }
@media (max-width: 1000px) {
  .mo { display:inherit !important; }
  .pc { display:none !important; }
  body { width:100%; position:relative; min-width:unset; }
  .footer { border-top:0; }
  .header, .footer, .main, .client-contents {
    padding: 0 16px; /* 요소들 간격 줄이기 */
  }

  .client-contents .inner {
    width: 100%; /* 전체 너비로 확장 */
    justify-content:center;
  }
  .client-contents .inner .image-wrap { display:none; margin:0 auto 24px; width:50%; }
  .client-contents .inner .contents-wrap { width:100%; max-height:unset; padding-bottom:0; }
  .game-wrap .game {
    width: 100%; /* 게임 항목 한 줄씩 배치 */
    margin-right: 0;
  }

  .client-contents { min-height:100vh; box-sizing:border-box; height:auto; }
  .client-contents .inner .description-wrap { margin-bottom:48px; width:100%; }
  .client-contents .inner .contents-wrap .title-wrap { width:80%; margin:0 auto; }
  .client-contents .inner .link-wrap a { width:100%; }
  .consent-section { width:100%; }
  .header { height:68px; }
  .header .front-section .category-wrap { display:none; }
  .header .util-wrap a { font-size:12px; padding:6px 8px; border-radius:4px; margin-left:8px; }
  .header .util-wrap button { font-size:12px; padding:6px 8px; border-radius:4px; margin-left:8px; }
  .header .util-wrap a.btn-mypage img { width:32px; }
  .footer .inner { width:100%; padding:36px 0; }
  .footer .inner .top-wrap .logo-wrap img { width:24px; }
  .footer .inner .top-wrap .logo-wrap a { font-size:24px; }
  .footer .inner .top-wrap .sns-wrap a img { width:20px; }
  .footer .inner .bottom-wrap { flex-direction:column; align-items:flex-start; }
  .footer .inner .bottom-wrap .info-wrap { flex-direction:column; align-items:flex-start; }
  .footer .inner .bottom-wrap .info-wrap .info { margin-bottom:8px; }
  .footer .inner .bottom-wrap .copy { margin:20px auto 0; }
  .client-contents .inner .link-wrap { margin-top:24px; }
  .main { width:100%; box-sizing:border-box; margin-top:52px; }
  .main .search-wrap { padding:100px 0; }
  .main .search-wrap .title { font-size:32px; }
  .client-contents { padding-top:92px; }
  .client-contents .path { font-size:16px; margin-bottom:16px; }
  .client-contents .path a { font-size:16px; }
  .client-contents .inner .contents-wrap .sub-title { width:100%; word-break:keep-all; }
  .login-form { width:100%; }
  .login-form .input-wrap .text-wrap { font-size:20px; }
  .login-form .input-wrap .label { font-size:18px; line-height:1.2 }
  .login-form .input-wrap label { font-size:18px; }
  .login-form .input-wrap input[type=number], .login-form .input-wrap input[type=password], .login-form .input-wrap input[type=text] { font-size:18px; }
  .login-form button { width:100%; }
  .login-form .button { width:100%; }
  .client-contents .inner .contents-wrap.cms .login-form { padding-top:0; }

  .client-contents .inner .contents-wrap.cms { max-height:unset; height:auto; }

  .client-contents .inner .description-wrap .title { font-size:18px; }
  .client-contents .inner .contents-wrap.cms .login-form .input-wrap .radio-wrap { width:100%; }
}
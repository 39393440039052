@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button, input, select, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	min-width:1280px;
}
ol, ul, li {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a { text-decoration: none; color:#000; }

button { background:#fff; border-radius:4px; line-height:1.5; cursor:pointer; }

:root {
	--green : #393363;
	--red : #BC4747;
	--black : #000;
	--gray : #98989A;
	--lineGray : #e0e0e0;
	--lightGreen : #393363;
	--bgGray : #262626;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='text'] { border:1px solid rgba(146,146,146,0.4); padding:12px; box-sizing:border-box; font-size:14px; }
input[type='password'] { border:1px solid rgba(146,146,146,0.4); padding:12px; box-sizing:border-box; font-size:14px; }
input[type='number'] { border:1px solid rgba(146,146,146,0.4); padding:12px; box-sizing:border-box; font-size:14px; }
input:focus { border: 1px solid var(--black); outline:unset; }
input[type='radio'] { margin-right:8px; padding:0; -webkit-appearance: none; -moz-appearance: none; appearance: none; width: 12px; height: 12px; border: 1px solid var(--lineGray); border-radius: 50%; outline: none; cursor: pointer; }
input[type='radio']:checked { background-color: var(--green); border: 2px solid #fff; box-shadow: 0 0 0 1px var(--green); }
select { border:1px solid rgba(146,146,146,0.4); padding:11px 12px; box-sizing:border-box; font-size:14px; } 
select:focus { border: 1px solid var(--black); outline:unset; }

textarea { border:1px solid rgba(146,146,146,0.4); padding:12px; box-sizing:border-box; font-size:14px; resize: none; line-height:1.4; }
textarea:focus { border: 1px solid var(--black); outline:unset; }

.displaynone { display:none; }
.ptNone { pointer-events:none; }
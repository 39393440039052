.gnb { position:fixed; top:0; left:0; width:100%; padding:16px 40px 15px; box-sizing:border-box; border-bottom:1px solid var(--gray); text-align:right; background:#fff; z-index:99; }
.snb { position:fixed; top:0; left:0; width:256px; background:var(--black); display:flex; flex-direction:column; height:100%; z-index:100; }
.snb .logo-wrap { font-size:24px; font-weight:700; }
.snb a { color:#fff; font-size:18px; padding:16px 24px; }

.contents { box-sizing:border-box; padding:72px 40px 120px 296px; }
.contents .titleArea { font-size:24px; font-weight:700; margin-bottom:24px; }
.contents .subCate { display:flex; align-items:center; margin-bottom:48px;} 
.contents .subCate a { display:block; font-size:18px; font-weight:400; color:var(--gray); margin-right:48px; padding-bottom:4px; border-bottom:1px solid #fff; }
.contents .subCate a.selected { border-bottom:2px solid var(--green); color:var(--green); font-weight:700; }

.list-form { display:flex; align-items:flex-start; justify-content:space-between; }
.list-form .list-wrap { width:304px; }
.list-form .list-wrap .title-wrap { display:flex; align-items:center; justify-content:space-between; margin-bottom:12px; }
.list-form .list-wrap .title-wrap p { font-size:16px; font-weight:700; }
.list-form .list-wrap .title-wrap a { font-size:14px; padding:4px 8px; background:var(--green); color:#fff; line-height:1.5; border-radius:4px; }
.list-form .list-wrap > ul { padding:24px; border:1px solid var(--lineGray); border-radius:4px; }
.list-form .list-wrap ul li { padding:4px 0 4px 24px; background:url('/public/img/icon-folder.svg') no-repeat 0 center;}
.list-form .list-wrap ul li .button-wrap { display:flex; }
.list-form .list-wrap ul .child-list { padding-left:24px; }
.list-form .list-wrap ul li:has(.selected) { background:url('/public/img/icon-folder-active.png') no-repeat 0 center; }
.list-form .list-wrap ul li button { padding:4px 8px; font-size:14px; font-weight:500; border-radius:0; width:100%; text-align:left; }
.list-form .list-wrap ul li button.selected { background:var(--green); color:#fff; }
.list-form .list-wrap ul li button.active { width:32px; text-align:center; color:#fff; border-left:1px solid #fff; background:var(--green); }
.list-form .list-wrap ul .child-list button.active { display:none; }
.list-form .list-wrap ul li button.disactive { display:none; }
.list-form .form-wrap { display:flex; flex-direction:column; width:calc(100% - 328px); }
.list-form .form-wrap .game-info { margin-bottom:60px; }
.list-form .form-wrap .title-wrap { display:flex; align-items:center; justify-content:space-between; margin-bottom:12px; }
.list-form .form-wrap .title-wrap .title { display:flex; align-items:center; font-size:16px; font-weight:700; }
.list-form .form-wrap .title-wrap .title img { margin-right:8px; }
.list-form .form-wrap .sub-title { padding:4px 12px; font-size:14px; color:#fff; background:var(--black); margin-bottom:24px; }

.list-form .form-wrap button { font-size:14px; font-weight:400; line-height:1.5; padding:4px 8px; border-radius:4px; background:var(--green); color:#fff; margin-left:10px; }
.list-form .form-wrap button.delete { background:var(--red); }

.list-form .form-wrap .input-wrap { display:flex; align-items:center; margin-bottom:10px; }
.list-form .form-wrap .input-wrap .label { width:130px; margin-right:24px; box-sizing:border-box; padding-left:10px; font-size:14px; color:var(--gray); }
.list-form .form-wrap .input-wrap input[type="text"] { width:calc(100% - 154px); }
.list-form .form-wrap .input-wrap textarea { width:calc(100% - 154px); }
.list-form .form-wrap .input-wrap label { margin-right:24px; font-size:14px; padding:12px 0; display:flex; align-items:center; cursor:pointer; }
.list-form .form-wrap .input-wrap .iconPreview { min-width:48px; max-width:48px; margin-right:12px; }
.list-form .form-wrap .input-wrap .bannerPreview { min-width:48px; max-width:100px; margin-right:12px; }
.list-form .form-wrap .input-wrap .file-button { font-size:14px; padding:4px 8px; border-radius:4px; background:var(--green); color:#fff; line-height:1.5; }

.list-form .form-wrap .input-wrapper { display:flex; align-items:center; position:relative; background:#f7f7f7; margin-bottom:24px; }
.list-form .form-wrap .input-wrapper .idx { display:flex; flex-direction:column; justify-content:center; align-items:center; width:86px; height:100%; }
.list-form .form-wrap .input-wrapper .idx .delete { margin-left:0; margin-top:18px;  }
.list-form .form-wrap .input-wrapper .inputs { background:#fff; width:calc(100% - 110px); padding-left:24px; }

.list-form .form-wrap .ingame-button { padding:0 0px 44px; }
.list-form .form-wrap .bottom-button-wrap { display:flex; align-items:center; justify-content:center; margin-top:24px; }

.util-wrap { display:flex; align-items:center; justify-content:space-between; margin-bottom:12px; }
.util-wrap .totalCnt { font-size:18px; font-weight:700; }
.util-wrap .button-wrap { display:flex; align-items:center; }
.util-wrap .button-wrap a { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; background: var(--green); color: #fff; margin-left: 10px;}
.util-wrap .button-wrap button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; background: var(--green); color: #fff; margin-left: 10px;}
.util-wrap .button-wrap button.delete { background:var(--red); }
.util-wrap .export-button { display:flex; align-items:center; }
.util-wrap .export-button button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; background: var(--green); color: #fff; margin-left: 10px;}
.util-wrap .export-button button.delete { background:var(--red); }
.util-wrap .change-grade select { margin-right:8px; }
.filters { display:flex; justify-content:space-between; padding:32px 0; }
.filters .filter select { border:1px solid var(--lineGray); border-radius:4px; padding:8px 16px; margin-right:12px; width:213px; box-sizing:border-box; color:#000; font-weight:500; }
.filters .filter button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 7px 12px; border-radius: 4px; background: var(--green); color: #fff; }
.filters .search { display:flex; align-items:center; border:1px solid var(--lineGray); border-radius:4px; padding:8px 24px; }
.filters .search input { border:0; padding:0; font-size:14px; line-height:1; }
.filters .search button { width:14px; height:14px; background:url('/public/img/icon-search.png'); font-size:0; background-size:14px 14px;}

.table-wrap { position:relative; }
.table-wrap .table-head { display:flex; align-items:center; justify-content:space-between; background:#262626; }
.table-wrap .table-head > div { font-size:16px; font-weight:500; color:#fff; padding:24px; box-sizing:border-box; text-align:center; }
.table-wrap .table-head > div.idx { width:100px; }
.table-wrap .table-head > div.game { width:160px; }
.table-wrap .table-head > div.team { width:160px; }
.table-wrap .table-head > div.controll { width:142px; }
.table-wrap .table-head > div.player { width:calc(100% - 562px); }
.table-wrap .table-head > div.settingName { width:calc(100% - 242px); }
.table-wrap .table-body { border:1px solid rgba(152,152,154,0.4); }
.table-wrap .table-body li { display:flex; align-items:center; justify-content:space-between; background:#fff; }
.table-wrap .table-body li:nth-child(2n) { background:#f0f0f0; }
.table-wrap .table-body li div { font-size:14px; font-weight:500; color:#000; padding:12px 4px; box-sizing:border-box; text-align:center; overflow:hidden; white-space: nowrap; text-overflow: ellipsis; }
.table-wrap .table-body li div.idx { width:100px; }
.table-wrap .table-body li div.idx input { cursor:pointer; }
.table-wrap .table-body li div.game { width:160px; }
.table-wrap .table-body li div.team { width:160px; }
.table-wrap .table-body li div.controll { width:142px; }
.table-wrap .table-body li div.controll button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; color: var(--gray); border:1px solid var(--gray);}
.table-wrap .table-body li div.controll button.delete { background:var(--red); border:1px solid var(--red); color:#fff; margin-left:8px; }
.table-wrap .table-body li div.controll button:nth-child(2) { margin-left:4px; }
.table-wrap .table-body li div.controll button.active { background:var(--green); color:#fff; }
.table-wrap .table-body li div.player { padding:0 24px; width:calc(100% - 562px); text-align:left; display:flex; align-items:center; cursor:pointer}
.table-wrap .table-body li div.player img { width:48px; height:48px; margin-right:6px; }
.table-wrap .table-body li div.settingName { padding:0 24px; width:calc(100% - 242px); text-align:left; display:flex; align-items:center; cursor:pointer }
.table-wrap .pagination { display:flex; align-items:center; justify-content:center; margin-top:36px; }
.table-wrap .pagination button { padding:6px 12px 4px; border-radius:4px; }
.table-wrap .pagination button.active { background:var(--green); color:#fff; }
.table-wrap .table-head > div.check { width:100px; }
.table-wrap .table-head > div.category { width:160px; }
.table-wrap .table-head > div.ttCnt { width:160px; }
.table-wrap .table-head > div.title { min-width:260px; }
.table-wrap .table-head > div.email { width:calc(100% - 1000px); }
.table-wrap .table-head > div.toolsettings { width:calc((100% - 342px) / 2) }
.table-wrap .table-head > div.shop { width:calc((100% - 342px) / 3) }
.table-wrap .table-head > div.page { width:calc((100% - 342px) / 4) }
.table-wrap .table-head > div.member { width:calc((100% - 850px) / 2) }
.table-wrap .table-head > div.member.small { width:170px; }
.table-wrap .table-body li div.check { width:100px; }
.table-wrap .table-body li div.category { width:160px; }
.table-wrap .table-body li div.ttCnt { width:160px; }
.table-wrap .table-body li div.title { padding:0 24px; min-width:260px; text-align:center; justify-content:center; display:flex; align-items:center; }
.table-wrap .table-body li div.toolsettings { width:calc((100% - 342px) / 2) }
.table-wrap .table-body li div.shop { width:calc((100% - 342px) / 3) }
.table-wrap .table-body li div.page { width:calc((100% - 342px) / 4) }
.table-wrap .table-body li div.member { width:calc((100% - 850px) / 2) }
.table-wrap .table-body li div.member.small { width:170px; }
.table-wrap .table-body li div.member > div { padding:8px; }
.table-wrap .table-body li div.page.link { flex-direction:row; align-items:center; display:flex; }
.table-wrap .table-body li div.page.link canvas { width:36px; margin-left:12px; }
.table-wrap .table-body li div.email { padding:0 24px; width:calc(100% - 1000px); text-align:left; display:flex; align-items:center; }
.table-wrap .table-body li div.board-subject { justify-content:center !important; }
.table-wrap .table-body li div.title button { background:unset; padding-bottom:0px; color:green; border-bottom:1px solid green; border-radius:0; }
.table-wrap .table-body li div.title { flex-direction:column; }
.table-wrap .table-body li div.title > div { padding:4px 0; }
.table-wrap .table-body li div.title.link { flex-direction:row; }
.table-wrap .table-body li div.title.link a { margin-right:12px; }
.table-wrap .table-body li div.title.link canvas { width:36px; }
.list-form .form-wrap .input-wrap.field { align-items:flex-start; }
.list-form .form-wrap .input-wrap.field .label { padding-top:16px; }

.field-wrap { display:flex; flex-direction:column; width: calc(100% - 154px); position:relative; }
.field-wrap .field { display:flex; align-items:center; justify-content:space-between; width:100%; margin-bottom:8px; }
.field-wrap .field .box { width:calc((100% - 24px) / 2); margin-right:24px; border:1px solid var(--lineGray) }
.field-wrap .field .box input,
.field-wrap .field .box select { border:0; pointer-events:none; }
.field-wrap .field .box.cont input { padding:0; color:var(--gray); }
.field-wrap .field .box select {-o-appearance: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; line-height:1.3; }
.field-wrap .field .box.cont span { font-size:14px; color:var(--gray); }
.field-wrap .field .box.cont .input-select { display:inline-flex; align-items:center; width:calc(100% - 154px); margin-left:8px; font-size:14px; color:var(--gray); }
.field-wrap .field .box.cont .input-select > div { display:flex; align-items:center; }
.field-wrap .field .box.cont .input-select > div:first-child::after { content: '/'; margin-left:12px; }
.field-wrap .field.ingame input { margin-right:24px; }
.field-wrap .field.ingame button { word-break:keep-all; margin-left:0; }
.field-wrap .field .radio-wrap { display:flex; align-items:center; width:calc(100% - 154px); margin-right:38px; }
.field-wrap .field.spec input,
.field-wrap .field.spec select { width:calc(100% / 2) !important; margin-right:24px; }
.field-wrap .field.spec button { word-break:keep-all; margin-left:0; }
.field-wrap button.btnAdd { width:fit-content; margin-left:0; margin-top:12px;}

.spec-wrap { padding-bottom:24px; border-bottom:1px solid var(--lineGray); margin-bottom:24px !important; }

.setting-form { border-bottom:1px solid var(--lineGray); margin-bottom:24px; }
.setting-form .button-bottom-wrap { padding-left:144px; margin-bottom:24px; margin-top:36px; }

.single-form-wrap .title-wrap { display:flex; align-items:center; justify-content:space-between; margin-bottom:12px; }
.single-form-wrap .title-wrap .title { display:flex; align-items:center; font-size:16px; font-weight:700; }
.single-form-wrap .title-wrap .title img { margin-right:8px; }
.single-form-wrap .sub-title { padding:4px 12px; font-size:14px; color:#fff; background:var(--black); margin-bottom:24px; margin-top:48px;}
.single-form-wrap button { font-size:14px; font-weight:400; line-height:1.5; padding:4px 8px; border-radius:4px; background:var(--green); color:#fff; margin-left:10px; }
.single-form-wrap button.delete { background:var(--red); }
.single-form-wrap button.clean { border:1px solid var(--gray); color:var(--gray); background:unset; }
.single-form-wrap button[type="submit"] { margin:48px auto; display:block; }
.single-form-wrap .tab-menu button { background:#fff; border:1px solid rgba(146,146,146,0.4); color:#555; margin-left:0; margin-right:12px; }
.single-form-wrap .tab-menu button.active { background:var(--green); color:#fff; }
.single-form-wrap .input-wrap { display:flex; align-items:center; margin-bottom:10px; }
.single-form-wrap .input-wrap.align-top { align-items:flex-start; margin-bottom:24px; }
.single-form-wrap .input-wrap.selection { margin-bottom:24px; padding-bottom:24px; border-bottom:1px solid var(--lineGray); }
.single-form-wrap .input-wrap .label { width:130px; margin-right:24px; box-sizing:border-box; padding-left:10px; font-size:14px; color:var(--gray); }
.single-form-wrap .input-wrap.align-top .label { width:140px; padding-top:16px; }
.single-form-wrap .input-wrap input[type="text"] { width:calc(100% - 154px); }
.single-form-wrap .input-wrap input[type="password"] { width:calc(100% - 154px); }
.single-form-wrap .input-wrap input[type='number'] { width:calc(100% - 154px); }
.single-form-wrap .input-wrap .radio-wrap { display:flex; align-items:center; }
.single-form-wrap .input-wrap .desc-wrapper { width:calc(100% - 154px); position:relative; }
.single-form-wrap .input-wrap .desc-wrapper input { margin-bottom:12px; }
.single-form-wrap .input-wrap .guide { font-size:14px; color:var(--gray); margin-right:8px; }
.single-form-wrap .input-wrap input.input-range { width:86px !important; margin-right:12px; }
.single-form-wrap .input-wrap textarea { width:calc(100% - 154px); }
.single-form-wrap .input-wrap .memo { width:100%; }
.single-form-wrap .input-wrap .memo textarea { width:100%; height:500px; }
.single-form-wrap .input-wrap select { width:calc(100% - 154px); }
.single-form-wrap .input-wrap .country-select { width:calc(100% - 154px); }
.single-form-wrap .input-wrap .country-select .css-13cymwt-control { border:1px solid rgba(146,146,146,0.4); padding:2px; box-sizing:border-box; font-size:14px; border-radius:0; } 
.single-form-wrap .input-wrap .country-select .css-t3ipsp-control { border:1px solid #000; padding:2px; box-sizing:border-box; font-size:14px; border-radius:0; box-shadow:unset; }
.single-form-wrap .input-wrap label { margin-right:24px; font-size:14px; padding:12px 0; display:flex; align-items:center; cursor:pointer; }
.single-form-wrap .input-wrap .iconPreview { min-width:48px; max-width:48px; margin-right:12px; }
.single-form-wrap .input-wrap .bannerPreview { min-width:48px; max-width:100px; margin-right:12px; }

.single-form-wrap .input-wrap .sns-wrap { width:calc(100% - 154px); display:flex; align-items:center; flex-wrap:wrap; }
.single-form-wrap .input-wrap .sns-wrap .sns { display:flex; align-items:center; margin:12px; }
.single-form-wrap .input-wrap .sns-wrap .sns.link { margin-left:0; }
.single-form-wrap .input-wrap .sns-wrap .sns:nth-child(4n) { margin-right:0; }
.single-form-wrap .input-wrap .sns-wrap .sns img { width:18px; margin-right:12px; }
.single-form-wrap .input-wrap .sns-wrap .sns .sns-input { border:1px solid var(--lineGray); padding:12px; box-sizing:border-box; font-size:14px; font-weight:300; }
.single-form-wrap .input-wrap .sns-wrap .sns .sns-input input { border:0; padding:0; width:200px; }

.single-form-wrap .input-wrap  .iconPreview { min-width:48px; max-width:48px; margin-right:12px; }
.single-form-wrap .input-wrap  .bannerPreview { min-width:48px; max-width:100px; margin-right:12px; }
.single-form-wrap .input-wrap  .file-button { font-size:14px; padding:4px 8px; border-radius:4px; background:var(--green); color:#fff; line-height:1.5; }

.single-form-wrap .wrap { border-bottom:1px solid var(--lineGray); margin-bottom:24px; }
.single-form-wrap .input-wrapper { display:flex; align-items:center; position:relative; background:#f7f7f7; margin-bottom:24px; }
.single-form-wrap .input-wrapper .idx { display:flex; flex-direction:column; justify-content:center; align-items:center; width:86px; height:100%; }
.single-form-wrap .input-wrapper .idx .delete { margin-left:0; margin-top:18px;  }
.single-form-wrap .input-wrapper .settings-wrap { background:#fff; width:calc(100% - 110px); padding-left:24px; }

.single-form-wrap .input-wrapper .idx .delete { margin-left:0; margin-top:18px;  }
.single-form-wrap .bottom-button-wrap { display:flex; align-items:center; justify-content:center; margin-top:24px; }
.single-form-wrap .bottom-center-button-wrap { display:flex; align-items:center; justify-content:center; margin:24px auto; }

.single-form-wrap .spec-input-wrap input,
.single-form-wrap .spec-input-wrap select { width:256px !important; }
.modal { position:fixed; top:0; left:0; background:rgba(255,255,255,0.8); width:100%; height:100%; }
.modal .modal-dialog { position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); background:#fff; box-sizing:border-box; border:1px solid var(--gray); padding:12px 24px 24px;  min-width:418px;  }
.modal .modal-dialog .modal-header { display:flex; align-items:center; justify-content:space-between; padding-bottom:4px; border-bottom:1px solid rgba(0,0,0,0.4); margin-bottom:12px; }
.modal .modal-dialog .modal-header .modal-title { font-size:16px; color:#111; font-weight:bold; }
.modal .modal-dialog .modal-header button { width:12px; height:13px; background:url('/public/img/icon-close.png'); font-size:0; background-size:12px 13px; padding:0; line-height:1; }

.modal .modal-dialog .error-message { font-size:14px; color:var(--red); padding-left:10px; margin-top:8px; }
.modal .modal-table { position:relative; width:892px; }
.modal .modal-table .table-head { display:flex; align-items:center; background:#262626; }
.modal .modal-table .table-head > div { font-size:16px; font-weight:500; color:#fff; padding:24px; box-sizing:border-box; text-align:center; }
.modal .modal-table .table-head > div.idx { width:100px; }
.modal .modal-table .table-head > div.thumbnail { width:140px; }
.modal .modal-table .table-head > div.cate { width:140px; }
.modal .modal-table .table-head > div.controll { width:140px; }
.modal .modal-table .table-head > div.name { width:calc(100% - 520px); }
.modal .modal-table .table-body li { display:flex; align-items:center; background:#fff; }
.modal .modal-table .table-body li:nth-child(2n) { background:#f0f0f0; }
.modal .modal-table .table-body li div { font-size:16px; font-weight:500; color:#000; padding:4px 20px; box-sizing:border-box; text-align:center; }
.modal .modal-table .table-body li .idx { width:100px; }
.modal .modal-table .table-body li .thumbnail { width:140px; }
.modal .modal-table .table-body li .thumbnail img { width:60px; }
.modal .modal-table .table-body li .cate { width:140px; }
.modal .modal-table .table-body li .controll { width:140px; }
.modal .modal-table .table-body li .name { width:calc(100% - 520px); text-align:left;}
.modal .modal-table .table-body li div.controll button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; color: var(--gray); border:1px solid var(--gray);}
.modal .modal-table .table-body li div.controll button.delete { background:var(--red); border:1px solid var(--red); color:#fff; margin-left:8px; }
.modal .filters { padding:0; margin-bottom:24px; }
.modal .filters .btn { width:14px; height:14px; background:url('/public/img/icon-search.png'); font-size:0; background-size:14px 14px; cursor:pointer; }
.modal .filters .search { padding:12px 16px; border-radius:0; width:100%; justify-content:space-between; }
.modal .filters .search input { width:100%; }
.modal .modal-table .table-title { margin-top:12px; font-size:16px; font-weight:bold; margin-bottom:12px; }
.modal .modalSubmit { background:var(--green); line-height:1; color:#fff; text-align:center; width:100%; font-size:16px; font-weight:bold; padding:16px 0; border-radius:4px; margin-left:0; margin-top:32px; }

.modal .modal-body { margin-top:12px; }
.modal .modal-body label { display:block; font-size:16px; font-weight:bold; color:var(--black); padding-top:12px; margin-bottom:12px; }
.modal .modal-body input[type='text'] { width:100%; }
.modal .modal-body select { width:100%; }
.modal .btnSubmit { font-size:14px; line-height:1; color:#fff; background:var(--green); border-radius:4px; padding:6px 8px; }
.modal .modal-body form > div { margin-bottom:12px; }
.modal .modal-body .label-wrap { display:flex; align-items:center; justify-content: space-between; margin-bottom:8px; }
.modal .modal-body form > div > .input-wrap { display:flex; align-items:center; margin-bottom:4px; }
.modal .modal-body form > div > .input-wrap input:last-child { margin-left:8px; }
.modal .modal-body form > div .d-flex { display:flex; align-items:center; margin-bottom:4px;}
.modal .modal-body form > div .d-flex input[type="text"] { width:120px; margin-right:12px; height:32px; }
.modal .modal-body form .delete { background:var(--red); color:#fff; font-size:14px; padding:4px 8px; }
.modal .modal-body form .confirm { background:var(--green); color:#fff; font-size:14px; padding:4px 8px; margin-top:12px; }
.modal .modal-footer { display:flex; align-items:center; justify-content:center; }
.modal .modal-footer .delete { background:var(--red); color:#fff; font-size:14px; padding:4px 8px; }
.modal .modal-footer .confirm { background:var(--green); margin-right:4px; color:#fff; font-size:14px; padding:4px 8px; }

.input-table-wrap { width: calc(100% - 154px); }
.input-table-wrap > button { margin-left:0; margin-bottom:24px; }
.input-table-wrap .table-head { display:flex; align-items:center; background:#262626; }
.input-table-wrap .table-head > div { font-size:16px; font-weight:500; color:#fff; padding:24px; box-sizing:border-box; text-align:center; }
.input-table-wrap .table-head > div.idx { width:100px; }
.input-table-wrap .table-head > div.thumbnail { width:140px; }
.input-table-wrap .table-head > div.cate { width:140px; }
.input-table-wrap .table-head > div.controll { width:140px; }
.input-table-wrap .table-head > div.link { width:calc((100% - 520px) / 2); }
.input-table-wrap .table-head > div.name { width:calc((100% - 520px) / 2); }
.input-table-wrap .table-body li { display:flex; align-items:center; background:#fff; }
.input-table-wrap .table-body li:nth-child(2n) { background:#f0f0f0; }
.input-table-wrap .table-body li div { font-size:16px; font-weight:500; color:#000; padding:4px 20px; box-sizing:border-box; text-align:center; }
.input-table-wrap .table-body li .idx { width:100px; }
.input-table-wrap .table-body li .thumbnail { width:140px; }
.input-table-wrap .table-body li .thumbnail img { width:60px; }
.input-table-wrap .table-body li .cate { width:140px; }
.input-table-wrap .table-body li .controll { width:140px; }
.input-table-wrap .table-body li .link { width:calc((100% - 520px) / 2); }
.input-table-wrap .table-body li .name { width:calc((100% - 520px) / 2); }
.input-table-wrap .table-body li div.controll button { font-size: 14px; font-weight: 400; line-height: 1.5; padding: 4px 8px; border-radius: 4px; color: var(--gray); border:1px solid var(--gray);}
.input-table-wrap .table-body li div.controll button.delete { background:var(--red); border:1px solid var(--red); color:#fff; margin-left:8px; }

.addSetting { width:400px !important; }

input[type="checkbox"] { width:20px; height:20px; border:1px solid rgba(146,146,146,0.4); margin-right:4px; border-radius:2px; -webkit-appearance: none; appearance: none; }
input[type="checkbox"]:checked { background:url('/public/img/icon-checked.png') no-repeat center center; background-size:100%; }
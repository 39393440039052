
.admin-login-container {
    max-width: 400px;
    margin: 120px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .admin-login-container h2 {
    text-align: center;
  }
  
  .admin-login-container .form-group {
    margin-bottom: 15px;
  }
  
  .admin-login-container .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .admin-login-container .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing:border-box;
  }
  
  .admin-login-container button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: var(--green);
    color: white;
    font-size: 16px;
  }
  
  .admin-login-container button:hover {
    background-color: var(--green);
  }
  
  .admin-login-container .error-message {
    color: red;
    text-align: center;
  }